import z from "zod";
import { addYears } from "date-fns";
import uniqueId from "lodash/uniqueId";
import { AvailabilityParams } from "src/hooks/useMews";
import { AddCustomerResult, AddReservationResult, ConfigurationResult, RestrictionsResult, ServiceAvailabilityResult } from "./mews.schema";
import { parseDate } from "./parse-date";

export type CustomerParams = {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
};

export type ReservationParams = {
  rateId: string;
  roomCategoryId: string;
  occupancy: Record<string, number>;
  bookingDate: {
    startDate: string;
    endDate: string;
  };
  notes: string;
  serviceId: string;
  customerId: string;
};

const fetchMews = <T extends z.ZodTypeAny>(url: string, options: RequestInit, schema: T) =>
  fetch(`${process.env.NEXT_PUBLIC_MEWS_BASE_URL}${url}`, {
    ...options,
    method: "POST",
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...JSON.parse(options.body as string),
      Client: process.env.NEXT_PUBLIC_MEWS_CLIENT_ID,
    }),
  })
    .then((res) => {
      if (!res.ok) {
        return res.text().then((text) => {
          throw new Error(res.statusText, {
            cause: text,
          });
        });
      }
      return res.json();
    })
    .then<z.infer<T>>(schema.parse);

const configurationRequest = fetchMews(
  "/api/distributor/v1/configuration/get",
  {
    body: JSON.stringify({
      Ids: process.env.NEXT_PUBLIC_MEWS_BOOKING_ENGINE_ID.split(","),
    }),
  },
  ConfigurationResult
);

const serviceAvailibilityRequest = ({ enterpriseId, serviceId, categoryId, startDate }: AvailabilityParams) => {
  const start = startDate ?? new Date();
  start.setDate(1);

  return fetchMews(
    "/api/distributor/v1/services/getAvailability",
    {
      body: JSON.stringify({
        EnterpriseId: enterpriseId,
        ServiceId: serviceId,
        StartUtc: start.toISOString(),
        EndUtc: addYears(start, 1).toISOString(),
        CategoryIds: [categoryId],
      }),
    },
    ServiceAvailabilityResult
  );
};

const createCustomerRequest = ({ email, first_name, last_name, phone }: CustomerParams) =>
  fetchMews(
    "/api/connector/v1/customers/add",
    {
      body: JSON.stringify({
        ClientToken: process.env.MEWS_CLIENT_TOKEN,
        AccessToken: process.env.MEWS_ACCESS_TOKEN,
        OverwriteExisting: true,
        FirstName: first_name,
        LastName: last_name,
        Email: email,
        Phone: phone,
      }),
    },
    AddCustomerResult
  );

const createReservationRequest = ({ bookingDate, rateId, roomCategoryId, occupancy, notes, serviceId, customerId }: ReservationParams) => {
  const startDate = parseDate(bookingDate.startDate);
  startDate.setHours(parseInt(process.env.CHECK_IN_HOURS), 0);

  const endDate = parseDate(bookingDate.endDate);
  endDate.setHours(parseInt(process.env.CHECK_OUT_HOURS), 0);

  return fetchMews(
    "/api/connector/v1/reservations/add",
    {
      body: JSON.stringify({
        ClientToken: process.env.MEWS_CLIENT_TOKEN,
        AccessToken: process.env.MEWS_ACCESS_TOKEN,
        ServiceId: serviceId,
        SendConfirmationEmail: false,
        Reservations: [
          {
            Identifier: uniqueId(),
            State: "Enquired",
            StartUtc: startDate.toISOString(),
            EndUtc: endDate.toISOString(),
            CustomerId: customerId,
            RequestedCategoryId: roomCategoryId,
            RateId: rateId,
            Notes: notes,
            PersonCounts: Object.entries(occupancy).map(([key, value]) => ({
              AgeCategoryId: key,
              Count: value,
            })),
          },
        ],
      }),
    },

    AddReservationResult
  );
};

const restrictionsRequest = (serviceId: string, categoryId) =>
  fetchMews(
    "/api/connector/v1/restrictions/getAll",
    {
      body: JSON.stringify({
        ClientToken: process.env.MEWS_CLIENT_TOKEN,
        AccessToken: process.env.MEWS_ACCESS_TOKEN,
        ServiceIds: [serviceId],
        ResourceCategoryIds: [categoryId],
      }),
    },
    RestrictionsResult
  );

export { configurationRequest, serviceAvailibilityRequest, createReservationRequest, restrictionsRequest, createCustomerRequest };
