import clsx from "clsx";
import React, { ButtonHTMLAttributes } from "react";

export const FormButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement> & { negative?: boolean }> = ({
  negative = false,
  type = "button",
  children,
  ...props
}) => {
  return (
    <button
      className={clsx(
        "h-12 my-4 px-4 py-2 sm:px-8 sm:py-4 border-2 md:border border-black text-xs text-center bg-main font-adrianna-extended font-bold tracking-wider uppercase md:shadow-formButton focus:outline-none focus:ring-0 focus:ring-offset-0 transform duration-150 md:hover:translate-y-formButton md:hover:translate-x-formButton hover:shadow-none hover:cursor-pointer active:translate-y-formButton active:translate-x-formButton active:shadow-none active:cursor-pointer",
        negative ? "bg-quatro text-white md:shadow-formButtonNegative border-none" : ""
      )}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};
