import React, { ReactNode, useEffect, useState } from "react";

export const NoSSR: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showChild, setShowChild] = useState(false);

  // Wait until after client-side hydration to show
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    // You can show some kind of placeholder UI here
    return null;
  }

  return <>{children}</>;
};
