import React from "react";
import { motion } from "framer-motion";

export const item = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0 },
};

export const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      staggerDirection: 1,
    },
  },
};

export const transition = { ease: "easeIn", duration: 0.4 };

export function SlideInUl({ children }) {
  return (
    <motion.ul variants={container} initial="hidden" exit="hide" animate="show">
      {children}
    </motion.ul>
  );
}
