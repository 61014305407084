/*
 *  This function is used to make sure that dates always return the day it is passed in the YYYY-MM-DD format.
 *  If we use `new Date()`, it interprets the date in the GMT timezone, and then returns it in the local timezone.
 */
export function parseDate(date?: string): Date {
  if (date === undefined) return undefined;
  if (date === null) return null;
  const [YYYY, MM, DD] = date.split("-");
  return new Date(parseInt(YYYY), parseInt(MM) - 1, parseInt(DD));
}
