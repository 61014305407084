import React, { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LoadingSpinner } from "../../loading";
import { StepContext } from "src/pages";
import clsx from "clsx";
import Image from "next/image";
import { Radio } from "../../radio-group";
import { useConfiguration } from "src/hooks/useMews";

export const HouseSelection: React.FC = () => {
  const { nextStep } = useContext(StepContext);
  const {
    control,
    reset,
    getValues,
    formState: { defaultValues },
  } = useFormContext();

  const { isLoading, categories, getCategoryImages, getServiceByCategoryId } = useConfiguration();

  if (isLoading) return <LoadingSpinner className="flex w-full items-center justify-center text-ternary" />;

  return (
    <Controller
      control={control}
      name="category_id"
      rules={{
        required: true,
        onChange: (e) => {
          reset({
            ...defaultValues,
            contactInfo: getValues("contactInfo"),
            category_id: e.target.value,
            service_id: getServiceByCategoryId(e.target.value),
          });
          nextStep();
        },
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Radio.Root
          aria-label="Select house"
          className={clsx(
            "relative ml-3 flex flex-col items-start gap-y-4 pt-8 sm:ml-0 sm:grid sm:grid-cols-[repeat(auto-fill,minmax(150px,1fr))] sm:items-stretch sm:gap-x-4 sm:gap-y-8"
          )}
          onValueChange={onChange}
          value={value}
          onBlur={onBlur}
          ref={ref}
        >
          {categories?.map((category) => {
            const { selection } = getCategoryImages(category.Id, category.ServiceId);

            return (
              <label
                key={category.Id}
                className="group flex flex-row-reverse items-center justify-end gap-4 pb-1 hover:cursor-pointer sm:h-full sm:flex-col"
              >
                <div className="flex flex-1 items-center justify-between gap-x-3 sm:flex-col sm:gap-x-0">
                  <div className="h-fit duration-200 group-hover:scale-110 group-hover:transform">
                    <div className="relative h-12 w-12 sm:mb-2 sm:h-24 sm:w-24 md:h-28 md:w-28 xs:h-16 xs:w-16">
                      {selection && (
                        <Image
                          src={selection}
                          alt={`${category.Name} logo`}
                          className="absolute inset-0 h-full w-full object-contain"
                          layout="fill"
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-grow flex-col gap-1 sm:gap-2">
                    <p className="flex-grow font-adrianna-extended text-content font-bold uppercase leading-content tracking-wider text-custom-black sm:row-span-2 sm:text-center sm:text-sm">
                      {category.Name}
                    </p>
                    {/* Need to add specific height (h-9) if they need two line for location */}
                    <p className="font-adrianna text-content font-light uppercase leading-content tracking-widest text-custom-black sm:row-span-1 sm:text-center">
                      {category.Description}
                    </p>
                    <p className="font-adrianna text-content text-xs font-light uppercase italic leading-content text-custom-black text-opacity-50 sm:row-span-1 sm:text-center">
                      ({category.NormalBedCount + category.ExtraBedCount} guests)
                    </p>
                  </div>
                </div>

                <Radio.Item
                  value={category.Id}
                  id={category.Id}
                  className="h-4 w-4 cursor-default rounded-full shadow-[inset_0_0_0_1px] shadow-custom-black outline-none"
                >
                  <Radio.Indicator
                    forceMount
                    className="relative flex h-full w-full items-center justify-center after:h-2.5 after:w-2.5 after:rounded-[50%] after:transition after:duration-200 after:data-[state=checked]:block after:data-[state=checked]:bg-custom-black after:data-[state=checked]:content-[''] after:data-[state=unchecked]:group-hover:bg-custom-black/30"
                  />
                </Radio.Item>
              </label>
            );
          })}
        </Radio.Root>
      )}
    />
  );
};
