import React, { useEffect, useRef } from "react";
import { useFormContext, get, RegisterOptions } from "react-hook-form";
import { emailPattern } from "src/utils/regex";
import autosize from "autosize";
import { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import clsx from "clsx";
import { PhoneWithCountriesInput } from "../phone-input";

export const BookingDetails: React.FC = () => {
  const { setValue } = useFormContext();
  return (
    <div className="flex flex-col space-y-6 pt-10">
      <TextField autoFocus placeholder="First name*" name="contactInfo.first_name" validation={{ required: true }} />
      <TextField placeholder="Last name*" name="contactInfo.last_name" validation={{ required: true }} />
      <TextField placeholder="E-mail*" name="contactInfo.email" validation={{ required: true, pattern: emailPattern }} />
      {/* <TextField placeholder="Company Name" name="contactInfo.company_name" /> */}
      <PhoneWithCountriesInput
        name="contactInfo.phone"
        placeholder="Mobile number*"
        onChange={(value) => {
          if (value && isPossiblePhoneNumber(value)) setValue("contactInfo.country" as `${string}`, parsePhoneNumber(value).country);
        }}
      />
      <TextField placeholder="Additional comments" name="contactInfo.notes" long />
    </div>
  );
};

export const TextField: React.FC<{
  name: string;
  placeholder: string;
  validation?: RegisterOptions;
  long?: boolean;
  autoFocus?: boolean;
}> = ({ name, placeholder, validation = { required: false }, long = false, autoFocus }) => {
  const textarea = useRef(null);
  const { register, formState } = useFormContext();
  const { ref, ...rest } = register(name as `${string}`, validation);

  useEffect(() => {
    autosize(textarea.current);
  }, [textarea]);

  const error = get(formState.errors, name);

  return (
    <div>
      <label htmlFor={name} className="sr-only">
        {name}
      </label>
      {long ? (
        <textarea
          ref={(e) => {
            ref(e);
            textarea.current = e;
          }}
          name={name}
          id={name}
          className={clsx(
            "block h-11 w-full border-0 border-b-2 border-gray-300 bg-main font-adrianna text-sm leading-6 tracking-wider outline-none focus:placeholder-transparent focus:ring-0",
            error ? "border-red-300 placeholder:text-red-900 focus:border-red-300" : "focus:border-gray-500"
          )}
          placeholder={placeholder}
          {...rest}
          autoFocus={autoFocus}
        />
      ) : (
        <input
          type="text"
          name={name}
          id={name}
          className={clsx(
            "block w-full border-0 border-b-2 border-gray-300 bg-main font-adrianna text-sm tracking-wider outline-none focus:placeholder-transparent focus:ring-0",
            error ? "border-red-300 placeholder:text-red-900 focus:border-red-300" : "placeholder:text-gray-500 focus:border-gray-500 "
          )}
          placeholder={placeholder}
          autoFocus={autoFocus}
          {...register(name as `${string}`, validation)}
        />
      )}
    </div>
  );
};

export default BookingDetails;
