import * as AccordionPrimitive from "@radix-ui/react-accordion";
import clsx from "clsx";
import React from "react";

export const Accordion = {
  Root: React.forwardRef<HTMLDivElement, AccordionPrimitive.AccordionSingleProps | AccordionPrimitive.AccordionMultipleProps>(function Item(
    { ...props },
    forwardedRef
  ) {
    return <AccordionPrimitive.Root {...props} ref={forwardedRef} />;
    return <AccordionPrimitive.Root {...props} ref={forwardedRef} />;
  }),
  Item: React.forwardRef<HTMLDivElement, AccordionPrimitive.AccordionItemProps>(function Item({ ...props }, forwardedRef) {
    return <AccordionPrimitive.Item {...props} ref={forwardedRef} />;
  }),
  Trigger: React.forwardRef<HTMLButtonElement, AccordionPrimitive.AccordionTriggerProps & { icon?: JSX.Element }>(function Trigger(
    { children, icon, ...props },
    forwardedRef
  ) {
    return (
      <AccordionPrimitive.Trigger {...props} ref={forwardedRef}>
        {children}
        {icon}
      </AccordionPrimitive.Trigger>
    );
  }),
  Content: React.forwardRef<HTMLDivElement, AccordionPrimitive.AccordionContentProps>(function Content(
    { className, ...props },
    forwardedRef
  ) {
    return (
      <AccordionPrimitive.Content
        className={clsx(
          "overflow-hidden transition-all data-[state=closed]:animate-accordionUp data-[state=open]:animate-accordionDown",
          className
        )}
        {...props}
        ref={forwardedRef}
      />
    );
  }),
  Header: React.forwardRef<HTMLDivElement, AccordionPrimitive.AccordionHeaderProps>(function Header({ ...props }, forwardedRef) {
    return <AccordionPrimitive.Header {...props} ref={forwardedRef} />;
  }),
};
