import React, { useContext, useEffect, useRef, useState } from "react";
import { addDays, format } from "date-fns";
import { useFormContext, useWatch } from "react-hook-form";
import { DatesDisplay } from "./summary";
import { motion } from "framer-motion";
import { item, SlideInUl, transition } from "./animation";
import { LoadingSpinner } from "../../loading";
import { parseDate } from "src/utils/parse-date";
import { StepContext } from "src/pages";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CustomDateRangePicker } from "../dateRangePicker";
import { FormButton } from "../FormButton";
import { useAvailability, useConfiguration, useRestrictions } from "src/hooks/useMews";

const DatesPreview = ({ children }) => {
  return (
    <div className="relative mt-4 flex items-center space-x-4 border-b pb-4 sm:mt-0">
      <img src="pictos/Arrow.svg" className="inline w-10" />
      <DatesDisplay />
      {children}
    </div>
  );
};

const CalendarInfo = ({ endDate }) => {
  const { setValue, getValues } = useFormContext();

  const handleStayExtension = (e) => {
    e.preventDefault();
    const newEndDate = format(addDays(parseDate(endDate), 3), "yyyy-MM-dd");
    setValue("bookingDates", { ...getValues("bookingDates"), endDate: newEndDate });
  };

  return (
    <div className="mb-2 flex items-center space-x-4 bg-secondary bg-opacity-50 p-4 sm:mt-0">
      <input
        id="extend"
        type="checkbox"
        className="rounded border-custom-black bg-main ring-transparent checked:bg-custom-black checked:text-white hover:cursor-pointer hover:bg-main checked:hover:bg-custom-black focus:outline-none focus:ring-0 focus:ring-offset-0 focus:checked:bg-custom-black active:text-custom-black"
        onClick={(e) => handleStayExtension(e)}
      />
      <label htmlFor="extend" className="font-adrianna text-base font-light text-gray-700">
        Extend your stay and book the weekend
      </label>
    </div>
  );
};

export const HolidaysDuration: React.FC = () => {
  const { nextStep, size } = useContext(StepContext);
  const { getValues, control, setValue } = useFormContext();
  const [availabilityStartDate, setAvailabilityStartDate] = useState(new Date());
  const datesSelectionRef = useRef(null);

  const categoryId = useWatch({ control, name: "category_id" });
  const serviceId = useWatch({ control, name: "service_id" });
  const bookingDates = useWatch({ control, name: "bookingDates" });
  const { startDate, endDate } = bookingDates;

  useEffect(() => {
    if (endDate && endDate !== startDate && datesSelectionRef.current) {
      datesSelectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [endDate]);

  const { getConfigurationByServiceId } = useConfiguration();
  const { bookableRestrictions } = useRestrictions(serviceId, categoryId);

  const configuration = getConfigurationByServiceId(serviceId);

  const { isLoading: isAvailabilityLoading, data: availabilityData } = useAvailability({
    enterpriseId: configuration.Enterprise.Id,
    serviceId: configuration.ServiceId,
    categoryId,
    startDate: availabilityStartDate,
  });

  if (isAvailabilityLoading /*|| isRestrictionLoading*/)
    return <LoadingSpinner className="flex w-full items-center justify-center text-ternary" />;

  return (
    <>
      <div className="mt-6 list-disc bg-secondary bg-opacity-50 p-4 font-adrianna text-sm tracking-wide text-custom-black text-opacity-80">
        If none of these dates suit you, select available dates and leave us a message in the comments.
      </div>
      <CustomDateRangePicker
        availabilities={availabilityData.Availabilities}
        // restrictions={restrictionData.Restrictions}
        restrictions={bookableRestrictions}
        size={size}
        setAvailabilityStartDate={setAvailabilityStartDate}
      />
      {endDate && (
        <div className="pt-4">
          <SlideInUl>
            <motion.li variants={item} transition={transition}>
              <DatesPreview>
                <button
                  type="button"
                  className="border border-gray-400 p-1 filter transition duration-200 hover:brightness-50 focus:outline-none"
                  onClick={() => setValue("bookingDates", { startDate: null, endDate: null })}
                >
                  <XMarkIcon className="h-3 w-3 stroke-gray-400 filter transition duration-200 hover:brightness-50" />
                </button>
              </DatesPreview>
            </motion.li>
            <motion.li variants={item} transition={transition}>
              <div className="flex justify-center md:block" ref={datesSelectionRef}>
                <FormButton onClick={() => nextStep()}>Continue to Guests</FormButton>
              </div>
            </motion.li>
          </SlideInUl>
        </div>
      )}
    </>
  );
};
