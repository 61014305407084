import { motion } from "framer-motion";
import React, { useContext, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { item, transition } from "./animation";
import Counter from "../Counter";
import { LoadingSpinner } from "../../loading";
import { StepContext } from "src/pages";
import { FormButton } from "../FormButton";
import { useConfiguration } from "src/hooks/useMews";

type SelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
  title: string;
  subtitle: string;
};

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(function Select({ title, subtitle, children, ...props }, ref) {
  return (
    <div className="mb-2 flex flex-row items-center justify-between pt-2">
      <div className="sm:flex xs:flex">
        <p className="w-24 font-adrianna-extended text-sm font-bold uppercase tracking-wider">{title}</p>
        <p className="mr-auto font-adrianna text-sm font-light tracking-wider sm:pl-10 xs:pl-6">{subtitle}</p>
      </div>
      <select
        ref={ref}
        className="client-form block w-28 appearance-none rounded-none border border-custom-black bg-transparent py-2 pl-8 font-adrianna-extended text-sm font-normal focus:border-ternary focus:outline-none focus:ring-0 focus:ring-ternary"
        {...props}
      >
        {children}
      </select>
    </div>
  );
});

const Guests: React.FC = () => {
  const { nextStep } = useContext(StepContext);
  const guestsSelectionRef = useRef(null);
  const guestsRef = useRef(null);

  const { register, control } = useFormContext();

  const categoryId = useWatch({ control, name: "category_id" });

  const adults = useWatch({ control, name: "guests.adult" });
  const children = useWatch({ control, name: "guests.child" });

  const { isLoading, getCurrentCategory } = useConfiguration();

  const category = getCurrentCategory(categoryId);
  const capacity = category?.NormalBedCount + category?.ExtraBedCount;

  if (isLoading) return <LoadingSpinner className="flex w-full items-center justify-center text-ternary" />;

  return (
    <>
      <div className="flex flex-col divide-y divide-gray-300 border-b border-gray-300 pt-6" ref={guestsRef}>
        <Select {...register("guests.adult", { max: capacity, valueAsNumber: true })} title="Adults" subtitle="Age 13 and above" id="adult">
          <Counter start={0} max={capacity} constraint={children} />
        </Select>
        <Select {...register("guests.child", { max: capacity, valueAsNumber: true })} title="Children" subtitle="Age 2 to 12" id="child">
          <Counter start={0} max={capacity} constraint={adults} />
        </Select>
        <Select {...register("guests.infant", { max: 20, valueAsNumber: true })} title="Infants" subtitle="Under 2" id="infant">
          <Counter start={0} max={capacity} constraint={0} />
        </Select>
      </div>
      <div className="mt-6 bg-secondary bg-opacity-50 p-4">
        <p className="font-adrianna text-sm italic tracking-wide text-custom-black text-opacity-80">
          {category?.Name} can host up to {capacity} adults and children. Infants are always welcome.
        </p>
      </div>
      {(adults !== 0 || children !== 0) && (
        <motion.div variants={item} initial="hidden" animate="show" transition={transition}>
          <div className="flex justify-center md:block" ref={guestsSelectionRef}>
            <FormButton onClick={nextStep}>Contact details</FormButton>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Guests;
