// import z from "zod";
// import { RestrictionsResult, ServiceAvailabilityResult } from "./mews.schema";
// import { addDays, differenceInDays, format, isAfter, isBefore, isSameDay, isSunday, nextSunday } from "date-fns";
// import { parse } from "duration-fns";

import { z } from "zod";
import { RestrictionsResult, ServiceAvailabilityResult } from "./mews.schema";
import { addDays, differenceInDays, format, isAfter, isBefore } from "date-fns";
import { parse } from "duration-fns";

type Availabilities = z.infer<typeof ServiceAvailabilityResult>["Availabilities"];
type Restrictions = z.infer<typeof RestrictionsResult>["Restrictions"];

function isRestrictionApplicable(calendarDay: string, startDate: string, restriction: Restrictions[number]): boolean {
  // console.log("calendarDay", calendarDay, restriction);
  const day = format(calendarDay, "EEEE");

  if (restriction.Exceptions.MinLength && differenceInDays(calendarDay, startDate) >= parse(restriction.Exceptions.MinLength).days) {
    return false;
  }

  if (restriction.Exceptions.MaxLength && differenceInDays(calendarDay, startDate) <= parse(restriction.Exceptions.MaxLength).days) {
    return false;
  }

  // If the day is not in the restriction days, return false
  if (restriction.Conditions.Days && !restriction.Conditions.Days.includes(day)) {
    return false;
  }

  // If the day is before the start date, return false
  if (restriction.Conditions.StartUtc && isBefore(calendarDay, format(restriction.Conditions.StartUtc, "yyyy-MM-dd"))) {
    return false;
  }

  // If the day is after the end date, return false
  if (restriction.Conditions.EndUtc && isAfter(calendarDay, format(restriction.Conditions.EndUtc, "yyyy-MM-dd"))) {
    return false;
  }

  // Otherwise, return true
  return true;
}

function isDayBlocked(calendarDay: string, startDate: string, restrictions: Restrictions, availabilities: Availabilities): boolean {
  //Block days before today
  if (isBefore(calendarDay, new Date())) return true;

  if (startDate) {
    //Block days before the start date
    if (isBefore(calendarDay, startDate)) return true;

    //Block day if there is no availability in the range between the start date and the calendar day
    const datesBetween = Array.from({ length: differenceInDays(calendarDay, startDate) }, (_, i) =>
      format(addDays(startDate, i), "yyyy-MM-dd")
    );
    return datesBetween.some((date) => availabilities[date] === 0);
  }

  //Block days with no availability
  if (!availabilities[calendarDay]) return true;

  //Block days with no availability between the previous bookable day and the calendar day
  const previousBookableDay = getPreviousBookableDay(calendarDay, startDate, restrictions, availabilities);
  if (!previousBookableDay) return true;

  const datesBetween = Array.from({ length: differenceInDays(calendarDay, previousBookableDay) }, (_, i) =>
    format(addDays(previousBookableDay, i), "yyyy-MM-dd")
  );
  return datesBetween.some((date) => availabilities[date] === 0);
}

function isOustideRange(calendarDay: string, startDate: string, restrictions: Restrictions, availabilities: Availabilities): boolean {
  if (isBookable(calendarDay, startDate, restrictions, availabilities)) {
    const minLength = restrictions.find(
      (restriction) =>
        restriction.Conditions.Type === "Stay" &&
        isRestrictionApplicable(calendarDay, startDate, restriction) &&
        restriction.Exceptions.MinLength
    )?.Exceptions.MinLength;

    const datesBetween = Array.from({ length: parse(minLength).days }, (_, i) => format(addDays(calendarDay, i), "yyyy-MM-dd"));
    return datesBetween.some((date) => availabilities[date] === 0);
  }

  const nextBookableDay = getNextBookableDay(calendarDay, startDate, restrictions, availabilities);
  if (isBefore(calendarDay, nextBookableDay)) return true;

  const datesBetween = Array.from({ length: differenceInDays(calendarDay, nextBookableDay) }, (_, i) =>
    format(addDays(nextBookableDay, i), "yyyy-MM-dd")
  );
  return datesBetween.some((date) => availabilities[date] === 0);
}

function isBookable(calendarDay: string, startDate: string, restrictions: Restrictions, availabilities: Availabilities) {
  if (!startDate && !availabilities[calendarDay]) return false;

  if (startDate) {
    return !restrictions.some(
      (restriction) => ["End", "Stay"].includes(restriction.Conditions.Type) && isRestrictionApplicable(calendarDay, startDate, restriction)
    );
  }

  return !restrictions.some(
    (restriction) => restriction.Conditions.Type === "Start" && isRestrictionApplicable(calendarDay, startDate, restriction)
  );
}

function getNextBookableDay(calendarDay: string, startDate: string, restrictions: Restrictions, availabilities: Availabilities) {
  const nextDay = format(addDays(calendarDay, 1), "yyyy-MM-dd");

  if (isBookable(nextDay, startDate, restrictions, availabilities)) {
    return nextDay;
  }

  return getNextBookableDay(nextDay, startDate, restrictions, availabilities);
}

function getPreviousBookableDay(calendarDay: string, startDate: string, restrictions: Restrictions, availabilities: Availabilities) {
  const previousDay = format(addDays(calendarDay, -1), "yyyy-MM-dd");

  if (isBefore(previousDay, new Date())) {
    return null;
  }

  if (isBookable(calendarDay, startDate, restrictions, availabilities)) {
    return calendarDay;
  }

  return getPreviousBookableDay(previousDay, startDate, restrictions, availabilities);
}

export { isDayBlocked, isOustideRange };
