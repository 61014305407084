import React from "react";

import { Controller, get, useFormContext } from "react-hook-form";

import PhoneInputWithCountry, { isPossiblePhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";

import "react-phone-number-input/style.css";

export const PhoneWithCountriesInput: React.FC<{
  placeholder: string;
  name: string;
  onChange?: (e: any) => void;
}> = ({ placeholder, name, ...props }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);

  const css = `
    .PhoneInputInput {
      color: #2D2D2D;
      font-size: 0.875rem;
      font-family: adrianna;
      letter-spacing: 0.05em !important;
      background-color: transparent;
      border-bottom: 2px solid rgba(209, 213, 219);
      border-top: none;
      border-right: none;
      border-left: none;
      box-shadow: none;
      padding: 8px 12px;
    }

    .PhoneInputInput::placeholder {
      color: rgb(107 114 128);
    }

    .PhoneInputError .PhoneInputInput {
      color: rgba(127, 29, 29);
      border-bottom: 2px solid rgba(252, 165, 165);
    }

    .PhoneInputInput:focus::placeholder {
      color: transparent;

    }

    .PhoneInputError .PhoneInputInput::placeholder {
      color: rgba(127, 29, 29);
    }

    .PhoneInputError .PhoneInputInput:focus::placeholder {
      color: transparent;
    }

    .PhoneInputInput:focus {
      outline: none; 
      box-shadow: none;
      border-bottom: 2px solid rgba(107,114,128,1.000)
    
}

    .PhoneInputError .PhoneInputInput:focus {
      border-bottom: 2px solid rgba(252, 165, 165);
    }
  `;

  return (
    <>
      <style>{css}</style>
      <Controller
        control={control}
        name={name as `${string}`}
        rules={{ required: true, validate: (phone) => isPossiblePhoneNumber(phone) }}
        render={({ field: { onChange, value } }) => (
          <PhoneInputWithCountry
            placeholder={placeholder}
            value={value}
            className={error ? "PhoneInputError" : ""}
            id={name}
            onChange={(value) => {
              props.onChange?.(value);
              return onChange(formatPhoneNumberIntl(value) || value);
            }}
            international
          />
        )}
      />
    </>
  );
};
