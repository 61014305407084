import { forwardRef } from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

export const Radio = {
  Root: forwardRef<HTMLDivElement, RadioGroupPrimitive.RadioGroupProps>(function Root({ ...props }, ref) {
    return <RadioGroupPrimitive.Root {...props} ref={ref} />;
  }),
  Item: ({ ...props }: RadioGroupPrimitive.RadioGroupItemProps): JSX.Element => {
    return <RadioGroupPrimitive.Item {...props} />;
  },
  Indicator: ({ ...props }: RadioGroupPrimitive.RadioIndicatorProps): JSX.Element => {
    return <RadioGroupPrimitive.Indicator {...props} />;
  },
};
