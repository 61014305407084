import clsx from "clsx";
import React, { ComponentPropsWithoutRef, FC, ReactNode } from "react";
import { social } from "src/pages";

export const PageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="flex h-screen flex-col">
      <Navbar />
      <div className="flex-1 bg-main">{children}</div>
      <Footer className="flex-shrink-0 bg-secondary md:block" />
    </div>
  );
};

export const Navbar: FC<ComponentPropsWithoutRef<"nav">> = ({ className, ...props }) => {
  return (
    <nav
      className={clsx("sticky top-0 z-10 h-16 shrink-0 border-b border-custom-black bg-main pr-1.5 md:h-16 2xl:px-0", className)}
      {...props}
    >
      <div className="mx-auto flex h-full w-full flex-shrink-0 items-center justify-between">
        <a href="https://iconic.house" className="flex h-full flex-shrink-0 items-center border-custom-black pl-5 md:border-r md:pr-6">
          <img className="block h-7 w-auto md:h-9 md:pr-1.5" src="/iconic.svg" alt="Iconic House" />
        </a>
        <div className="flex h-full flex-col justify-center border-custom-black pr-7 text-center md:border-l md:px-7">
          <p className="font-adrianna-extended text-xs font-semibold tracking-wide md:text-sm">Contact us</p>
          <a className="font-adrianna-extended text-xs tracking-wide text-quatro md:text-sm" href="tel:+33608358706">
            +33 6 08 35 87 06
          </a>
        </div>
      </div>
    </nav>
  );
};

export const Footer: FC<ComponentPropsWithoutRef<"footer">> = (props) => {
  return (
    <footer className="bg-secondary" aria-labelledby="footerHeading" {...props}>
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      {/* px-4 py-8 md:grid md:grid-cols-6 md:px-16 lg:px-32 */}
      <div className="mx-auto h-fit items-start 2xl:max-w-headerAndFooter 2xl:px-0">
        <div className="flex flex-col items-center justify-between px-4 py-8 pb-6 md:flex-row md:px-16 lg:px-32">
          <div className="mb-10 flex h-full items-center justify-center md:mb-0 md:justify-start">
            <a href="https://iconic.house">
              <img className="mx-auto ml-0 block w-36 place-items-center md:w-48 lg:w-56" src="/iconic.svg" alt="Iconic House" />
            </a>
          </div>
          <div className="space-y-10 md:flex md:gap-10 md:space-y-0 md:text-left lg:gap-20 xl:gap-32">
            <div className="space-y-2 text-center md:space-y-4 md:text-left">
              <h3 className="font-adrianna-extended text-sm font-bold uppercase tracking-widest text-black">Contact us</h3>
              <ul>
                <li>
                  <a href="mailto:contact@iconic.house" className="font-adrianna text-base font-light text-black hover:text-gray-700">
                    contact@iconic.house
                  </a>
                </li>
                <li>
                  <a href="tel:+33608358706" className="font-adrianna text-base font-light text-black hover:text-gray-700">
                    +33 6 08 35 87 06
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center space-y-4 md:text-left">
              <h3 className="font-adrianna-extended text-sm font-bold uppercase tracking-widest text-black">Follow us</h3>
              <ul className="mt-4 space-y-4">
                <div className="flex space-x-3">
                  {social.map((item) => (
                    <a key={item.name} href={item.href} target="_blank" rel="noreferrer" className="text-black hover:text-gray-700 ">
                      <span className="sr-only">{item.name}</span>
                      <img className="h-10 w-10" src={item.icon} aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="block py-4 text-center font-adrianna text-sm font-light text-black"> */}
        <p className="p-4 text-center font-adrianna text-sm font-light text-black ">
          © Iconic House, All rights reserved –{" "}
          <a href="/CGV_GB_Iconic_House.pdf" target="_blank" rel="noopener noreferrer" className="duration-200 hover:text-gray-700">
            Privacy & Terms
          </a>
        </p>
        {/* </div> */}
      </div>
    </footer>
  );
};
