// This code is a modification of https://github.com/rehooks/component-size that fixes the resizeObserver loop limit exceeded issue
// more info https://github.com/rehooks/component-size/issues/31

import debounce from "lodash/debounce";
import { RefObject, useCallback, useLayoutEffect, useState } from "react";

interface ComponentSize {
  width: number;
  height: number;
}

function getSize(el): ComponentSize {
  if (!el) {
    return {
      width: 0,
      height: 0,
    };
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  };
}

export function useComponentSize<T extends Element = Element>(ref: RefObject<T>): ComponentSize {
  const [componentSize, setComponentSize] = useState<ComponentSize>(getSize(ref ? ref.current : {}));

  const handleResize = useCallback(
    function handleResize() {
      if (ref.current) {
        setComponentSize(getSize(ref.current));
      }
    },
    [ref]
  );

  // this can only be called on the client no ssr https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
  useLayoutEffect(
    function () {
      if (!ref.current) {
        return;
      }

      handleResize();

      if (typeof ResizeObserver === "function") {
        let resizeObserver = new ResizeObserver(
          // ResizeObserver loop limit exceeded quick fix
          debounce(() => {
            handleResize();
          }, 200)
        );
        resizeObserver.observe(ref.current);

        return function () {
          resizeObserver.disconnect();
          resizeObserver = null;
        };
      } else {
        window.addEventListener("resize", handleResize);

        return function () {
          window.removeEventListener("resize", handleResize);
        };
      }
    },
    [handleResize, ref]
  );

  return componentSize;
}
