import range from "lodash/range";
import React from "react";

const Counter: React.FC<{ start: number; max: number; constraint?: number }> = ({ start, max = 10, constraint = 0 }) => {
  return (
    <>
      {range(start, max + 1).map((num) => (
        <option key={num} value={num} disabled={num > max - constraint}>
          {num}
        </option>
      ))}
    </>
  );
};

export default Counter;
