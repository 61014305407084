import { useQuery } from "@tanstack/react-query";
import { configurationRequest, serviceAvailibilityRequest } from "src/utils/mews.client";
import { RestrictionsResult } from "src/utils/mews.schema";

export type AvailabilityParams = {
  enterpriseId: string;
  serviceId: string;
  categoryId: string;
  startDate?: Date;
};

const useConfiguration = () => {
  const { data, ...query } = useQuery({
    queryKey: ["configuration"],
    queryFn: () => configurationRequest,
  });

  const categories =
    data?.Configurations.flatMap((config) =>
      config.Enterprise.Categories.map((category) => ({
        ...category,
        ServiceId: config.ServiceId,
      }))
    ) || [];

  const getCurrentCategory = (categoryId: string) => {
    return categories.find((category) => category.Id === categoryId);
  };

  const getCategoryImages = (categoryId: string | null, serviceId: string | null) => {
    const configuration = data?.Configurations.find((config) => config.ServiceId === serviceId);
    if (!configuration)
      return {
        selection: null,
        icon: null,
        view: null,
      };

    const imageIds = configuration.Enterprise.CategoryImageAssignments.filter((image) => image.CategoryId === categoryId).sort(
      (a, b) => a.Ordering - b.Ordering
    );

    const [selection, icon, view] = imageIds || [];

    return {
      selection: selection?.ImageId ? `${data.ImageBaseUrl}/${selection.ImageId}` : null,
      icon: icon?.ImageId ? `${data.ImageBaseUrl}/${icon.ImageId}` : null,
      view: view?.ImageId ? `${data.ImageBaseUrl}/${view.ImageId}` : null,
    };
  };

  const getServiceByCategoryId = (categoryId: string) => {
    return categories.find((category) => category.Id === categoryId)?.ServiceId;
  };

  const getConfigurationByServiceId = (serviceId: string) => {
    return data?.Configurations.find((config) => config.ServiceId === serviceId);
  };
  const getServiceByServiceId = (serviceId: string) => {
    return data?.Services.find((service) => service.Id === serviceId);
  };

  const getServiceById = (serviceId: string) => {
    return data?.Configurations.find((config) => config.ServiceId === serviceId);
  };

  const getRatesByServiceId = (serviceId: string) => {
    const service = getServiceById(serviceId);
    return service?.Enterprise.Rates;
  };

  return {
    data,
    ...query,
    getCurrentCategory,
    getCategoryImages,
    getServiceByCategoryId,
    getConfigurationByServiceId,
    getServiceByServiceId,
    getServiceById,
    getRatesByServiceId,
    categories,
  };
};

const useAvailability = (availibalityParams: AvailabilityParams) =>
  useQuery({
    queryKey: ["availibity", availibalityParams.categoryId, availibalityParams.startDate.toISOString()],
    queryFn: () => serviceAvailibilityRequest(availibalityParams),
    keepPreviousData: true,
    enabled: !!availibalityParams.enterpriseId && !!availibalityParams.serviceId && !!availibalityParams.categoryId,
    staleTime: Infinity,
  });

const useRestrictions = (serviceId: string, categoryId: string) => {
  const { getRatesByServiceId } = useConfiguration();

  const rates = getRatesByServiceId(serviceId);
  const bookingRateGroup = rates?.find((rate) => rate.IsPrivate)?.RateGroupId;

  const restrictions = useQuery({
    queryKey: ["restrictions", serviceId, categoryId],
    queryFn: () =>
      fetch(`/api/mews/restrictions/${serviceId}/${categoryId}`)
        .then((res) => res.json())
        .then(RestrictionsResult.parse),
    enabled: !!serviceId,
    staleTime: Infinity,
  });

  const bookableRestrictions =
    restrictions.data?.Restrictions.filter((restriction) => restriction.Conditions.RateGroupId === bookingRateGroup) ?? [];

  return {
    ...restrictions,
    bookableRestrictions,
  };
};

export { useConfiguration, useAvailability, useRestrictions };
