import React from "react";
import { differenceInCalendarDays, format } from "date-fns";
import { useFormContext, useWatch } from "react-hook-form";
import { parseDate } from "src/utils/parse-date";
import { useConfiguration } from "src/hooks/useMews";
import { z } from "zod";
import { Category } from "src/utils/mews.schema";
import Image from "next/image";

export const Summary: React.FC<{ totalGuests: number }> = ({ totalGuests }) => {
  const { control } = useFormContext();

  const categoryId = useWatch({ name: "category_id", control });

  const { getCurrentCategory } = useConfiguration();

  const category = getCurrentCategory(categoryId);

  return (
    <div className="mt-20 h-minContent space-y-7 overflow-y-auto md:sticky md:top-20 md:mt-0">
      <p className="border-b border-black font-adrianna-extended font-bold uppercase tracking-wide">Booking summary</p>
      {!!categoryId && (
        <>
          <HouseSummary category={category} />
          <StayAndGuests totalGuests={totalGuests} />
        </>
      )}
    </div>
  );
};

const HouseSummary: React.FC<{ category: z.infer<typeof Category> & { ServiceId: string } }> = ({ category }) => {
  const { getCategoryImages } = useConfiguration();

  const { view, selection } = getCategoryImages(category?.Id, category?.ServiceId);

  return (
    <>
      <Image src={view} alt={`${category?.Name} logo`} width="0" height="0" sizes="100vw" className="h-auto w-full" />
      <div className="mt-8 flex space-x-4">
        <div className="h-20 w-20 flex-shrink-0">
          <Image src={selection} alt="House logo" width="80" height="80" className="mx-auto h-full w-auto" />
        </div>
        <div>
          <p className="font-adrianna-extended text-lg font-bold uppercase tracking-wider">{category?.Name}</p>
          <p className="font-adrianna text-sm font-light uppercase italic tracking-widest">{category?.Description}</p>
          {/* {house?.included && <p className="inline font-adrianna text-sm font-bold tracking-wide">{`Included: ${house?.included}`}</p>} */}
        </div>
      </div>
    </>
  );
};

export const DatesDisplay: React.FC = () => {
  const { getValues } = useFormContext();
  return (
    <p className="inline font-adrianna text-sm font-light tracking-wide">
      From <span className="whitespace-nowrap font-bold">{format(new Date(getValues("bookingDates.startDate")), "MMMM do, yyyy")}</span>{" "}
      {getValues("bookingDates.endDate") && (
        <>
          to{"\u00A0"}
          <span className="whitespace-nowrap font-bold">
            {format(parseDate(getValues("bookingDates.endDate")), "MMMM do, yyyy")}
          </span> |{" "}
          <span className="font-bold">
            {differenceInCalendarDays(parseDate(getValues("bookingDates.endDate")), parseDate(getValues("bookingDates.startDate")))}
            {"\u00A0"}nights
          </span>
        </>
      )}
    </p>
  );
};

const DatesSummary: React.FC = () => {
  return (
    <div className="mt-3 flex items-start justify-between space-x-4">
      <div className="flex space-x-2">
        <img src="pictos/calendar.svg" className="inline h-full w-4" />
        <DatesDisplay />
      </div>
      <p className="font-adrianna text-sm font-light tracking-wider">Available</p>
    </div>
  );
};

const GuestsSummary: React.FC<{ totalGuests: number; className: string }> = ({ totalGuests, ...props }) => {
  const { getValues } = useFormContext();

  const adultsSentence = `${getValues("guests.adult")} adult${getValues("guests.adult") != 1 ? "s" : ""}`;
  const childrenSentence = `${getValues("guests.child")} child${getValues("guests.child") != 1 ? "ren" : ""}`;
  const infantsSentence = `${getValues("guests.infant")} infant${getValues("guests.infant") != 1 ? "s" : ""}`;

  const sentences = [adultsSentence, childrenSentence, infantsSentence].filter((sentence) => sentence !== "0");

  return (
    <div className="space-x-6 bg-red-500" {...props}>
      <img src="pictos/people.svg" className="mr-2 inline h-full w-4" />
      <p className="mt-2 inline font-adrianna text-sm font-light tracking-wide">
        <span className="font-adrianna font-bold">{`${totalGuests} guest${totalGuests != 1 ? "s" : ""}`}</span> {sentences.join(", ")}
      </p>
    </div>
  );
};

export const StayAndGuests: React.FC<{ totalGuests: number }> = ({ totalGuests }) => {
  const { control } = useFormContext();

  const startDate = useWatch({ name: "bookingDates.startDate", control });

  return (
    <div>
      {(startDate || totalGuests !== 0) && (
        <p className="border-b border-custom-black border-opacity-50 pb-2 font-adrianna-extended text-xs font-light uppercase text-gray-400">
          Stay
        </p>
      )}
      {startDate && <DatesSummary />}
      {totalGuests !== 0 && <GuestsSummary totalGuests={totalGuests} className={startDate ? "" : `mt-2`} />}
    </div>
  );
};
