import React, { useEffect, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DayPickerRangeController } from "react-dates";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
// import { isDayBlocked, isOustideRange } from "src/utils/calendar-booking-mews";
import z from "zod";
import { RestrictionsResult, ServiceAvailabilityResult } from "src/utils/mews.schema";
import { isDayBlocked, isOustideRange } from "src/utils/calendar-booking-mews";

const Chevron = ({ rotation = null, top, right }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: top,
        right: right,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg className="h-5 w-5 md:h-4 md:w-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          transform={rotation}
          d="M10.5429 0.542969L11.9571 1.95718L6.1642 7.75008L11.9571 13.543L10.5429 14.9572L3.33577 7.75008L10.5429 0.542969Z"
        />
      </svg>
    </div>
  );
};

const css = `
.DayPicker {
  background-color: transparent !important;
  width: 100%;
}

.DayPicker_transitionContainer__vertical {
  height: 570px !important;
}

.DayPicker_transitionContainer__horizontal{
  height: 323px !important;
}

.CalendarMonth {
  background-color: transparent !important;
}

.DayPicker_transitionContainer {
  background-color: transparent !important;
}

.CalendarMonthGrid {
  background-color: transparent !important;
}

.DayPicker_calendarInfo__horizontal {
  background-color: transparent !important;
}

.CalendarDay {
  color: black;
  font-weight: 900;
  background-color: transparent;
  font-family: Inter;
  border-color: #A3A3A3;
  relative
}

.CalendarDay:hover {
  color: white;
  background-color: #B9C9C0;
  border-color: #A3A3A3;
}

.CalendarDay__selected:active,
.CalendarDay__selected {
  background-color: #94A89D !important;
  color: white;
  border-color: #A3A3A3;
}

.CalendarDay__selected:hover{
  background-color: #94A89D;
  color: white;
  border-color: #A3A3A3;
}

.CalendarDay__blocked_out_of_range{
  cursor: default;
  color: black;
  text-decoration: none;
  font-weight: 300;
  background-color: transparent;
  border-color: #A3A3A3;
}

.CalendarDay__blocked_out_of_range:hover {
  color: black;
  cursor: default;
  background-color: transparent;
  position: relative;
}

.CalendarDay__blocked_out_of_range:hover::before {
  font-size: 11px;
  font-family: adrianna;
  position: absolute;
  white-space: nowrap;
  color: white;
  left: 50%;
  top: 110%;
  transform: translate(-50%, 0);
  background-color: black;
  padding: 3px 7px;
}

.CalendarDay__blocked_out_of_range:hover::after {
  content: "";
  display: block;
  border-top: 0px solid transparent;
  border-bottom: 5px solid black;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
}

.CalendarDay__blocked_calendar{
  background-color: rgba(245, 245, 245, 0.3);
  color: rgba(0, 0, 0, 0.3);
  border-color: #A3A3A3;
  text-decoration: none;
  font-weight: 400;
  cursor: default;
}

.CalendarDay__blocked_calendar:hover{
  background-color: rgba(245, 245, 245, 0.3);
  color: rgba(0, 0, 0, 0.3);
  border-color: #A3A3A3;
  position: relative;
}

.CalendarDay__blocked_calendar:hover::before {
  content: none !important;
}

.CalendarDay__blocked_calendar:hover::after {
  content: none;
  border: none;
}

.CalendarMonth_caption {
  text-align: left;
  font-family: adrianna;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: black;
}

.CalendarDay__selected_span {
  background-color: #B9C9C0 !important;
  border-color: #A3A3A3;
  color: white;
}

.CalendarDay__selected_span:hover {
  background-color: #B9C9C0 !important;
  border-color: #A3A3A3;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span {
  background-color: #B9C9C0;
  color: white;
  border-color: #A3A3A3;
}

.DayPicker_weekHeader_li {
  font-family: adrianna;
  font-weight: bold;
  color: black;
}

.DayPickerNavigation__vertical {
  width: 286px;
  margin: 0 auto;
}

`;

export const CustomDateRangePicker: React.FC<{
  size: { width: number; height: number };
  availabilities: z.infer<typeof ServiceAvailabilityResult>["Availabilities"];
  restrictions: z.infer<typeof RestrictionsResult>["Restrictions"];
  setAvailabilityStartDate?: React.Dispatch<React.SetStateAction<Date>>;
}> = ({ size, availabilities, restrictions, setAvailabilityStartDate }) => {
  const [focusedInput, setFocusedInput] = useState("startDate");
  const { control, setValue, watch } = useFormContext();
  const dayPickerRef = React.useRef(null);

  const { width } = size;

  const [startDate, endDate] = watch(["bookingDates.startDate", "bookingDates.endDate"]);

  // Make sure focus on end date if you come back to this step
  useEffect(() => {
    setFocusedInput("endDate");
  }, []);

  //FIXME: Check we dont have timezone issue
  // Reset date if you have a start date and click on start date
  useEffect(() => {
    if (startDate === endDate) {
      setValue("bookingDates.startDate", null);
      setValue("bookingDates.endDate", null);
    }
  }, [startDate, endDate]);

  const isVertical = width <= 550;

  const cssResponsive = `
.CalendarDay__blocked_out_of_range:hover::before {
  content: "${startDate ? "Check-out not available" : "Check-in not available"}";
}

.CalendarMonth_caption {
  margin-bottom: ${isVertical ? "" : "20px"};
}

.DayPicker_weekHeader {
  top: ${isVertical ? "53px" : "70px"};
}
`;

  // Makes sure there is always a focus and the good one depending you actions
  if (focusedInput === "endDate" && startDate === null) {
    setFocusedInput("startDate");
  } else if (focusedInput === null) {
    setFocusedInput("endDate");
  }

  const handleChangeMonth = () => setAvailabilityStartDate(dayPickerRef.current?.state.currentMonth.toDate());

  return (
    <div className="flex justify-center">
      <style>
        {css}
        {cssResponsive}
      </style>
      <Controller
        control={control}
        name="bookingDates"
        render={({ field: { onChange, value } }) => (
          <DayPickerRangeController
            onDatesChange={(e) => {
              onChange({
                startDate: e.startDate === null ? null : e.startDate.format("YYYY-MM-DD"),
                endDate: e.endDate === null ? null : e.endDate.format("YYYY-MM-DD"),
              });
            }}
            startDate={value.startDate === null ? null : moment(value.startDate, "YYYY-MM-DD")}
            endDate={value.endDate === null ? null : moment(value.endDate, "YYYY-MM-DD")}
            focusedInput={focusedInput}
            onFocusChange={(el) => {
              setFocusedInput(el);
            }}
            transitionDuration={230}
            daySize={37}
            numberOfMonths={2}
            firstDayOfWeek={1}
            hideKeyboardShortcutsPanel
            minimumNights={0}
            isDayBlocked={(day) => isDayBlocked(day.format("YYYY-MM-DD"), startDate, restrictions, availabilities)}
            /*
             * I use out of range as blocked between bookable date so it can be hovered when you have a
             * start date. Dayblocked doesn't allow us to do that!
             */
            isOutsideRange={(day) => isOustideRange(day.format("YYYY-MM-DD"), startDate, restrictions, availabilities)}
            orientation={isVertical ? "vertical" : "horizontal"}
            navPrev={
              isVertical ? <Chevron rotation="rotate(90,7.75,7.75)" top={25} right={45} /> : <Chevron rotation="" top={25} right={45} />
            }
            navNext={
              isVertical ? (
                <Chevron rotation="rotate(-90,7.75,7.75)" top={25} right={15} />
              ) : (
                <Chevron rotation="rotate(180,7.75,7.75)" top={25} right={20} />
              )
            }
            onNextMonthClick={handleChangeMonth}
            onPrevMonthClick={handleChangeMonth}
            ref={dayPickerRef}
            noBorder
          />
        )}
      />
    </div>
  );
};
